import React, {useEffect, useContext} from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductPreviewContext from "../context/ProductPreviewContext";

export const HandlePastProductLinks = () => {
  const { productName } = useParams();
  const navigate = useNavigate()

  const { fetchProductsByQuery } = useContext(ProductPreviewContext);

  useEffect(() => {
    fetchFun().catch(err=>console.log(err))
    // eslint-disable-next-line
  }, [productName]);

  const fetchFun = async () => {
    const queryObject = {
      $or: [
        { name: { $regex: productName, $options: "i" } }, // Case-insensitive search on productName
        { _id: productName }, // Match against _id
      ],
    };
    const res = await fetchProductsByQuery(
      queryObject,
      0,
      1,
      {},
      'name _id'
    );
   if(res.length > 0 ){
     navigate(`/products/decor/${res[0]._id}/${res[0].name.trim().replace('&', 'and').split(' ').join('-')}`)
   }else{
    navigate("/")
   }
  };

  return <Spinner />;
};

const Spinner = () => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};
