import { useContext, memo } from "react";
import AuthContext from "../../context/AuthContext";
import LoginForm from "../../components/authSidebar/LoginForm";
import OTPform from "../../components/authSidebar/OTPform";
import { Link } from "react-router-dom";
import RegisterForm from "../../components/authSidebar/RegisterForm";
import CompRenderingContext from "../../context/CompRenderingContext";
import Header from "./Header";

const MainForm = ({ modalClose }) => {
  const {
    authPage: { login, otp },
  } = useContext(AuthContext);
  const { setIsLoginModal } = useContext(CompRenderingContext);

  const handleCLoseLoginModals = () => {
    setIsLoginModal(false);
  };

  return (
    <div className="">
      {(login || otp) && <Header modalClose={modalClose} />}
      <div
        className={`px-6 w-full ${
          login || otp ? "top-[35vh]" : " top-[1px]"
        }  bottom-0  fixed overflow-scroll  scroll-bar-remove`}
      >
        {login ? (
          <LoginForm modalClose={modalClose} />
        ) : otp ? (
          <OTPform modalClose={modalClose} />
        ) : (
          <RegisterForm modalClose={modalClose} />
        )}
      </div>
      {/* <LoginForm modalClose={modalClose} /> */}

      {/* <p className="text-xs py-4 text-[#817C7C]">
        By clicking on Login, I accept the{" "}
        <Link
          onClick={handleCLoseLoginModals}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
          to="/terms"
        >
          Terms & Conditions
        </Link>
        &
        <Link
          onClick={handleCLoseLoginModals}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
          to="/privacy"
        >
          Privacy Policy
        </Link>
      </p> */}
    </div>
  );
};

export default memo(MainForm);
