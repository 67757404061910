import React from "react";
import { FaLocationArrow } from "react-icons/fa6";

export default function CityTagIndicator({ modalData, setModalData }) {
  const handleCityDeletion = () => {
    setModalData((p) => ({
      isSearchLoading: false,
      isUpdatingLocation: false,
      searchText: "",
      isFullModal: false,
      cityTag: "",
      matchingCities: [],
      popularLocalities: [],
      searchingPopularLocalities: false,
      isDropDown: false,
      dropDownList: [],
      isNotServiceableArea: false,
      launchingSoon: false,
    }));
  };

  if (modalData.cityTag === "") {
    return "";
  }

  return (
    <div className=" rounded-full  items-center mt-1 flex w-full justify-between ">
      <div className="text-[1rem] flex gap-1  capitalize  items-center ">
        <FaLocationArrow className="text-[1.25rem] text-[#2136d4] animate-offer-icon" />
        <div className="flex flex-col ">
          <div className=" font-[400] text-[#2136d4] text-[1rem] leading-[15px]">
            {modalData.cityTag}
          </div>
          <div className="text-[.75rem] leading-[12px] text-[grey]"> City </div>
        </div>
      </div>
      <div
        onClick={() => handleCityDeletion()}
        className="text-[.75rem] cursor-pointer font-[500] border rounded-full py-[2px] border-[#2136d4] px-2 text-[#2136d4]"
      >
        Change
      </div>
    </div>
  );
}
