import React from "react";
import { MdLocationOff } from "react-icons/md";
import ServicealbleAreas from "./ServicealbleAreas";
import { FaCircleArrowRight, FaWhatsapp } from "react-icons/fa6";

export default function NotServiceable({
  modalData,
  searchInputField,
  setModalData,
  handleCitySelect,
}) {
  const content = "Hi, I'm unable to find desired service location.";
  return (
    <div className="flex flex-col justify-center mt-4 items-center w-full ">
      <div className="flex items-center flex-col w-full mb-1">
        <MdLocationOff className="text-[3.5rem] text-[#2136d4]" />{" "}
        <div className="text-[1.25rem] font-[500] mt-2">
          Location not serviceable
        </div>{" "}
        <div className="text-[.75rem] font-[400] text-center w-[80%] text-[grey] leading-4 ">
          But You can Reach us! We will try to arrange the service at your
          location if feasible.
        </div>
      </div>

      <button
        onClick={() => {
          searchInputField?.current?.focus();
          setModalData((p) => ({
            ...p,
            searchText: "",
            isNotServiceableArea: false,
          }));
        }}
        className="text-white blue-gradient mirror-Animation before:w-[50px] rounded-lg py-2 flex w-full  justify-center font-[500] text-[1.125rem] mt-2 bg-[#2136d4] "
      >
        Try another location
      </button>
      <div
        onClick={() => {
          window.location.href = `https://wa.me/9046727027?text=${content}`;
        }}
        className="flex w-full mirror-Animation before:w-[50px]  justify-between items-center rounded-lg border mt-4 p-4 gap-4 border-[#ebebeb]"
      >
        <div className="flex flex-col gap-3">
          <p className="text-[1rem] font-[500] leading-[22px]">
            Need service in nearby area of our serviceable city?
          </p>
          <p className="text-[1rem] flex items-center gap-2">
            Chat with us
            <FaCircleArrowRight className="text-[1.25rem]" />
          </p>
        </div>
        <div className="">
          <FaWhatsapp className="text-[4rem] text-[#31a73f] " />
        </div>
      </div>
      <ServicealbleAreas
        handleCitySelect={handleCitySelect}
        modalData={modalData}
      />
    </div>
  );
}
