import moment from "moment";
import React, { createContext, useState, useEffect } from "react";

const GLobalStoreContext = createContext();

export const GLobalStoreProvider = ({ children }) => {
  const isBrowser = typeof window !== "undefined";
  const user = isBrowser ? JSON.parse(localStorage?.getItem("user")) : null;

  // --------------- ** STATES ** -----------------

  //---- mobile app info like version ----
  // const [appInfo, setAppInfo] = useState({
  // });
  const [statusBarHeight, setStatusBarHeight] = useState(8);

  // ----- user's orders list
  const [allOrders, setAllOrders] = useState(null);

  const [userStates, setUserStates] = useState({
    userDefaultLocation: JSON.parse(localStorage?.getItem("USER_STATE"))
      ? JSON.parse(localStorage?.getItem("USER_STATE")).defaultLocation
      : {
          city: "siliguri",
          location: "sevoke road",
          pincode: "734001",
          state: "west bengal",
          _id: "slg-sevoke-road-734001",
        },
    defaultReadyTime: "",
    defaultArrivingTime: "",
    defaultCelebrationDate: moment().add(1, "days").toISOString(),
  });

  // ------- date and slot informations --------------------
  const [orderInfo, setOrderInfo] = useState({
    convenienceFee: 0,
    selectedTimeSlot: "",
    selectedDate: moment().add(1, "day").format("DD-MM-YYYY"), // it will set tomorrow date
  });

  const [wishlistedArray, setWishlistedArray] = useState([]); // stores the wishlisted product _id
  const [wishlistedArrayObj, setWishlistedArrayObj] = useState([]); // stores the wishlisted product's complete info

  const [freebiesArray, setFreebiesArray] = useState({
    isLoading: false,
    data: null,
  });

  //------------------ ** USE EFFECTS ** ------------------

  //useEffect for fetching mobile app version
  // useEffect(() => {
  //   if(window.ReactNativeWebView) { document.addEventListener('message', (event) => {
  //       // Check if the received message is of type 'versionInfo'
  //       console.log("checking message")
  //       const data = JSON.parse(event.data);
  //       const send={...data}
  //       if (data.type === 'versionInfo') {
  //       //   // Update your state based on the received version information
  //         setAppInfo((prev) => ({
  //           ...prev,
  //           isMobileApp:window.ReactNativeWebView,
  //           ...data.appInfo
  //           // isMobileApp:true,
  //           // currentVersion: data.data.currentVersion,
  //           // latestVersion: data.data.latestVersion,
  //           // isUpdateAvailable: data.data.currentVersion !== data.data.latestVersion,
  //         }));
  //       const message = { type: "fromWebView",data:data };
  //       window.ReactNativeWebView.postMessage(JSON.stringify(message));
  //       }
  //     }) }
  // }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        if (window.ReactNativeWebView) {
          const receivedData = JSON.parse(event.data);
          const responseMessage = {
            data: "Received statusBarHeight",
            dataReceived: receivedData,
          };
          window.ReactNativeWebView.postMessage(
            JSON.stringify(responseMessage)
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    handleContentLoadOnUserChange();
    // eslint-disable-next-line
  }, []);

  const handleContentLoadOnUserChange = async () => {
    // wishlist first time load
    user && toggleWishlistItem();
  };

  const toggleWishlistItem = async (product, type) => {
    try {
      const { handleWishlistedProducts } = await import("../api/wishlistApi");
      const res = await handleWishlistedProducts({
        mode: type,
        productCode: product?._id,
      });

      if (res?.isSuccess) {
        setWishlistedArrayObj(res.wishlistedProducts);
        const resWishlistedArray = res.wishlistedProducts.map(
          (curr) => curr._id
        );
        setWishlistedArray(resWishlistedArray);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //  send message to native
  /*
      sendMessageToNative({
        action: "",
        trigger:"vibration",
        impactLevel:"light",  // medium // heavy
      });
  */
  const sendMessageToNative = (props) => { 
    const message = {
      ...props,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  const handleVibrate=()=>{
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
  }

  return (
    <GLobalStoreContext.Provider
      value={{
        allOrders,
        setAllOrders,
        orderInfo,
        setOrderInfo,
        wishlistedArray,
        setWishlistedArray,
        wishlistedArrayObj,
        setWishlistedArrayObj,
        toggleWishlistItem,
        freebiesArray,
        setFreebiesArray,
        userStates,
        setUserStates,
        // appInfo,
        sendMessageToNative,
        handleVibrate,
        statusBarHeight,
      }}
    >
      {children}
    </GLobalStoreContext.Provider>
  );
};

export default GLobalStoreContext;
