import "./Error.css";
import { memo } from "react";
const Error = ({ error, setError }) => {
  setTimeout(() => {
    setError && setError("");
  }, 2000);

  return (
    <>
      <p className="text-red-600 mb-3 text-sm font-semibold shake">{error}</p>
    </>
  );
};

export default memo(Error);
