import React from "react";
import { BsSearch } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import CityTagIndicator from "./CityTagIndicator";

export default function SearchBar({
  handleSearch,
  handleContainerUp,
  modalData,
  setModalData,
  searchInputField,
}) {
  return (
    <div className="flex gap-2 w-full flex-col absolute top-[3.5rem] px-4  left-0 right-0 ">
      {/* search bar */}
      <CityTagIndicator modalData={modalData} setModalData={setModalData} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
        onClick={handleContainerUp}
        className="flex justify-between  items-center bg-white rounded-md border border-[#d9d9d9] w-full    h-[2.5rem] "
      >
        <div
          className={`flex text-[black] z-[3] items-center px-2 text-[1.2rem] justify-center`}
        >
          <BsSearch className=" text-[#d9d9d9]  " />
        </div>{" "}
        <input
          type="text"
          onChange={(e) => {
            setModalData((p) => ({
              ...p,
              searchText: e.target.value,
            }));
          }}
          ref={searchInputField}
          placeholder="Search for Pincode or Area name"
          value={modalData.searchText}
          className=" w-full bg-[#ffffff00] rounded-lg placeholder:text-[#b5b5b5] h-full  flex focus:outline-none text-[.825rem]  "
        />
        {modalData.searchText.length > 0 && (
          <div
            onClick={() => {
              setModalData((p) => ({
                ...p,
                searchText: "",
                popularLocalities: [],
                launchingSoon: false,
                isNotServiceableArea: false,
              }));
            }}
            className={`flex text-[#13131388] z-[3] items-center px-2 text-[1.2rem] cursor-pointer justify-center`}
          >
            {modalData.isSearchLoading ? (
              <div className="loader w-[1rem] "></div>
            ) : (
              <RxCross2 className=" text-[#d9d9d9] " />
            )}
          </div>
        )}
        <button type="submit" className="hidden">
          search
        </button>
      </form>
    </div>
  );
}
