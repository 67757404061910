import React, { useContext, useState } from "react";
import AddressContext from "../../../context/AddressContext";
import { FaLocationDot } from "react-icons/fa6";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import { FaMobileAlt } from "react-icons/fa";
import { GoHome } from "react-icons/go";
import { MdOutlineWorkOutline } from "react-icons/md";
import { AnimatePresence } from "framer-motion";
import AddAndModiAddress from "../../myAddress/subComponents/AddAndModiAddress/AddAndModiAddress";
import GLobalStoreContext from "../../../context/GlobalStoreContext";
import Toast1 from "../toastModals/Toast1";

export default function SavedAddressesSection({ handleClose }) {
  const { allAddress, setSelectedAddress } = useContext(AddressContext);
  const { setUserStates } = useContext(GLobalStoreContext);
  const [modalsRenderInfo, setModalsRenderInfo] = useState({
    addAndModiAddress: {
      isRender: false,
      options: {
        customZIndex: 15,
        isEdit: false,
        editingAddressInfo: null,
      },
    },
    toast1: {
      isRender: false,
      text: "",
      options: {
        customZIndex: 15,
      },
    },
  });
  const handleAddressSelect = async (address) => {
    if (address.location) {
      setSelectedAddress(address);
      setUserStates((p) => ({ ...p, userDefaultLocation: address.location }));
      localStorage.setItem(
        "USER_STATE",
        JSON.stringify({
          defaultLocation: {
            _id: address.location._id,
            city: address.location.city,
            state: address.location.state,
            pincode: address.location.pincode,
            location: address.location.location,
          },
        })
      );

      handleClose();
    } else {
      setModalsRenderInfo((p) => ({
        ...p,
        toast1: {
          isRender: true,
          text: "Location is not set in this address! Go to my address to set the location in this address.",
          options: {
            customZIndex: 15,
          },
        },
      }));
    }
  };
  return (
    <>
      <AnimatePresence>
        {modalsRenderInfo.addAndModiAddress.isRender && (
          <AddAndModiAddress
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modalsRenderInfo.toast1.isRender && (
          <Toast1
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>
      <div className="w-full text-[.875rem] mb-1 px-1 mt-4 text-[grey]  ">
        Saved Addresses
      </div>

      {!allAddress ? (
        <div className=" ebo_skeleton w-full rounded-lg h-[10rem]"></div>
      ) : (
        <>
          {allAddress.map((curr, idx) => {
            const addresslabel = curr.addressLabel.trim().toLowerCase();
            return (
              <div
                onClick={() => handleAddressSelect(curr)}
                key={idx}
                className="w-full flex p-4 gap-2 mb-2 shadow-sm border-[#d9d9d9]  border text-[.875rem] rounded-lg   font-[500]  "
              >
                {addresslabel === "home" ? (
                  <GoHome className="text-[2rem] text-[#2136d4]" />
                ) : addresslabel === "work" ? (
                  <MdOutlineWorkOutline className="text-[2rem] text-[#2136d4]" />
                ) : addresslabel === "friends and family" ? (
                  <HiOutlineUserGroup className="text-[2rem] text-[#2136d4]" />
                ) : (
                  <IoLocationOutline className="text-[2rem] text-[#2136d4]" />
                )}

                <div className="flex flex-col w-full text-[.875rem] text-[grey] ">
                  <div className="flex gap-2 mb-1 text-[black] items-center ">
                    <span className="font-[600] capitalize text-[1rem]">
                      {curr.receiversDetails?.name}
                    </span>
                    <span className="border bg-[#efefef] text-[grey] text-[.625rem] uppercase rounded px-2 ">
                      {curr.addressLabel}
                    </span>
                  </div>
                  <div className=" leading-[18px] font-[400] text-[black] tracking-[.4px]">
                    {curr.address1}
                  </div>
                  <div className=" leading-[18px] font-[400] text-[black] tracking-[.4px]">
                    {curr.address2}
                  </div>
                  <div className=" leading-[18px] font-[400] capitalize text-[black] tracking-[.4px]">
                    {curr.location?.pincode}-
                    {curr.location?.location &&
                      curr.location?.location !== "default" &&
                      curr.location?.location + ", "}{" "}
                    {curr.location?.city}
                  </div>
                  <div className="flex  gap-[2px] mt-2 items-center text-[black] font-[400]">
                    <FaMobileAlt className=" relative bottom-[.5px] text-[.625rem]" />{" "}
                    {curr.receiversDetails?.mobileNumber}
                  </div>
                </div>
              </div>
            );
          })}

          <div
            onClick={() =>
              setModalsRenderInfo((p) => ({
                ...p,
                addAndModiAddress: {
                  isRender: true,
                  options: {
                    customZIndex: 15,
                  },
                },
              }))
            }
            className="w-full flex blue-gradient   mirror-Animation before:w-[20px]  justify-center gap-1  items-center text-[1rem] rounded-lg min-h-[3rem] h-[3rem]  font-[500]  "
          >
            <FaLocationDot className="text-[1.25rem]" />
            Add new address
          </div>
        </>
      )}
    </>
  );
}
