import { memo } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Button = ({ text, type, id, isLoading, disabled }) => {
  // const {
  //   initialState: { isLoading },
  // } = useContext(AuthContext);
  // console.log(disabled);
  let disableStyle =
    disabled === true
      ? " opacity-70 cursor-not-allowed"
      : "mirror-Animation opacity-100 cursor-pointer";

  return (
    <button
      type={type}
      className={`${disableStyle} blue-gradient before:w-[200px] text-white w-full py-3 rounded-md active:scale-[0.99] transition-all uppercase font-medium md:font-semibold hover:opacity-[0.9] "cursor-pointer"
     `}
      id={id ? id : ""}
    >
      {isLoading ? <ClipLoader size={20} color={"white"} /> : text}
    </button>
  );
};

export default memo(Button);
