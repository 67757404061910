import React from "react";
import { serviceableArea } from "./contants";
import { FaCheck } from "react-icons/fa6";

export default function ServicealbleAreas({ handleCitySelect, modalData }) {
  return (
    <div className="flex flex-col w-full bg-gradient-to-b rounded-xl px-4 mt-4 h-[10ren]  from-blue-50 to-white">
      {" "}
      <div className="w-full text-[.875rem] px-1  text-[grey] pt-2  ">
        Serviceable city
      </div>
      {/* serviceable cities container */}
      <div className="flex justify-between text-[.875rem] gap-4 mt-2 w-full">
        {serviceableArea.map((curr) => (
          <div
            onClick={() => {
              !curr.isLaunching && handleCitySelect(curr.city);
            }}
            className="flex relative items-center gap-2 flex-col "
          >
            {curr.city === modalData.cityTag && (
              <div className="flex items-center justify-center absolute top-0  rounded-xl w-[96px] h-[66px] bg-[#0000005b] z-[1]">
                <FaCheck className="text-white text-[1.25rem]" />
              </div>
            )}
            <img
              className="rounded-xl w-[96px] h-[66px]"
              src={curr.img}
              alt="siliguri"
            />
            <span>{curr.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
