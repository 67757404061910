import React, { useState, useRef, useEffect } from "react";

export default function OtpInputBoxes({ otp, setOtp, isVerificationStarted }) {
  const inputRefs = useRef(Array(6).fill(null)); // Create refs array

  // Auto focus on first input box
  useEffect(() => {
    setTimeout(() => {
      const firstInput = inputRefs.current[0];
      if (firstInput) {
        firstInput.focus();
      }
    }, 2000);
  }, []);

  const handleChange = (event, index) => {
    const { value } = event.target;

    // check for conditions if it is single digit value then proceed with normal logic and if it is multi digit value (means we are pasting code) then calling direct fill function
    if (value.length > 1) {
      handleFillInput(value, index);
      return;
    } //

    const newValue = value.replace(/[^0-9]/g, ""); // Allow only numbers
    const updatedOtp = [...otp];
    updatedOtp[index] = newValue;
    setOtp(updatedOtp);

    // Move focus to the next input if a digit is entered
    if (newValue && index < inputRefs.current.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleFillInput = (values, index) => {
    const newValues = values.split("").filter((char) => !isNaN(char));
    const updatedOtp = [...otp];
    let lastFilledIndex = -1;

    // Update the OTP values
    newValues.forEach((value, i) => {
      const currentIndex = index + i;
      if (currentIndex < updatedOtp.length) {
        if (!isNaN(value)) {
          updatedOtp[currentIndex] = value;
          lastFilledIndex = currentIndex;
        }
      }
    });

    setOtp(updatedOtp);

    // Move focus to the last filled input
    const lastFilledInput = inputRefs.current[lastFilledIndex + 1];
    if (lastFilledInput) {
      lastFilledInput.focus();
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    handleFillInput(pastedData, index);
  };

  const handleBackPress = (event, index) => {
    const { key } = event;

    if (key === "Backspace") {
      const currentValue = otp[index];

      if (!currentValue) {
        // Case 1: If there is no value in the current box, go back to the previous box and clear it
        if (index > 0) {
          const prevInput = inputRefs.current[index - 1];
          if (prevInput) {
            const updatedOtp = [...otp];
            updatedOtp[index - 1] = "";
            setOtp(updatedOtp);
            prevInput.focus();
          }
        }
      } else {
        // Case 2: If there is a value in the current box, clear it and keep focus on the current box
        const updatedOtp = [...otp];
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    }
  };

  const blurEffectStyle = {
    filter: "blur(0.5px)",
    pointerEvents: "none",
  };

  return (
    <div className="flex flex-nowrap justify-between">
      {otp.map((digit, index) => (
        <React.Fragment key={index}>
          <div
            style={isVerificationStarted ? blurEffectStyle : {}}
            className={`relative w-12 h-12 rounded-[10px] overflow-hidden border `}
          >
            <input
              type="text"
              inputmode="numeric"
              value={digit}
              onChange={(event) => handleChange(event, index)}
              onKeyDown={(event) => {
                event.key === "Backspace" && handleBackPress(event, index);
              }}
              onPaste={(event) => handlePaste(event, index)}
              disabled={isVerificationStarted}
              className="w-full h-full bg-gray-100 text-center text-2xl font-bold outline-none focus:outline-none"
              maxLength="6"
              ref={(inputRef) => (inputRefs.current[index] = inputRef)} // Attach refs correctly
              aria-label={`OTP digit ${index + 1}`}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
