import React, { memo} from "react";
import PopUpComponents from "./PopUpComponents";
import EachRoute from "./EachRoute";
import "../components/homeComponents/EboHome.css";
import BottomNav from "../components/navbar/BottomNav";
import useFetchEboData from "../hooks/useFetchEboData";

const MainRouter = () => {

  useFetchEboData();

  return (
    <div className=" bg-[white]">
      <PopUpComponents />
      <EachRoute />
       <BottomNav /> 
    </div>
  );
};

export default memo(MainRouter);
