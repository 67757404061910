import axios from "axios";
const BaseUrl = process.env.REACT_APP_BACKEND_URL;

export const locationSearchByQuery = async ({
  queryObject,
  limit,
  searchText,
  onlyServiceableArea,
}) => {
  try {
    const res = await axios.post(`${BaseUrl}/api/location/get_locatitons`, {
      queryObject,
      limit,
      searchText,
      onlyServiceableArea,
    });
    return res.data;
  } catch (error) {
    return { isSuccess: false };
  }
};
