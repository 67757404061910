import React, { useContext, useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { locationSearchByQuery } from "../../../api/locationApi";
import SavedAddressesSection from "./SavedAddressesSection";
import DropDownList from "./DropDownList";
import SearchBar from "./SearchBar";
import PopularLocalitiesList from "./PopularLocalitiesList";
import { motion } from "framer-motion";
import { update_user_api } from "../../../api/userApi";
import AuthContext from "../../../context/AuthContext";
import GLobalStoreContext from "../../../context/GlobalStoreContext";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaLocationPinLock } from "react-icons/fa6";
import CompRenderingContext from "../../../context/CompRenderingContext";
import AddressContext from "../../../context/AddressContext";
import "./style.css";
import ServicealbleAreas from "./ServicealbleAreas";
import NotServiceable from "./NotServiceable";

/*
 steps to use this component
 step 1: create state

   const [modalsRenderInfo, setModalsRenderInfo] = useState({
    selectLocationModal: {
      isRender: false,
      options: {
        isSavedAddressHidden: false
      },
    },
  });

 step 2:: import the component conditionally

    <AnimatePresence>
        {modalsRenderInfo.addAndModiAddress.isRender && (
          <SelectLocationModal
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>

  step 3: change the state conditionally to render the modal
*/

export default function SelectLocationModal({
  modalsRenderInfo,
  setModalsRenderInfo,
}) {
  const searchInputField = useRef(null);
  const { user, setInitialState, initialState } = useContext(AuthContext);
  const { setIsLoginModal } = useContext(CompRenderingContext);

  const { setSelectedAddress } = useContext(AddressContext);
  const { setUserStates } = useContext(GLobalStoreContext);

  const [modalData, setModalData] = useState({
    isSearchLoading: false,
    isUpdatingLocation: false,
    searchText: "",
    isFullModal: false,
    cityTag: "",
    matchingCities: [],
    popularLocalities: [],
    searchingPopularLocalities: false,
    isDropDown: false,
    dropDownList: [],
    isNotServiceableArea: false,
    isNoLocationFound: false,
    launchingSoon: false,
  });

  const [isSelectionProcess, setIsSelectionProcess] = useState(false);

  const handleClose = () => {
    setModalsRenderInfo({
      selectLocationModal: {
        isRender: false,
        options: {
          zIndex: 999,
        },
      },
    });
  };

  useEffect(() => {
    modalData.cityTag && handlePopularAreasFetch();
    if (modalData.isFullModal && modalData.searchText.length >= 4) {
      handleSearch();
    } else {
      setModalData((p) => ({
        ...p,
        isSearchLoading: false,
        isDropDown: false,
        dropDownList: [],
      }));
    }
    // eslint-disable-next-line
  }, [modalData.searchText]);

  const handleSearch = async () => {
    try {
      if (modalData.launchingSoon) {
        return;
      }
      setModalData((p) => ({
        ...p,
        isSearchLoading: true,
        isDropDown: false,
        isNotServiceableArea: false,
        matchingCities: [],
      }));
      const queryObject = {
        $or: [
          { location: { $regex: modalData.searchText, $options: "i" } },
          { pincode: parseInt(modalData.searchText) || 0 },
          { city: { $regex: modalData.searchText, $options: "i" } },
        ],
      };

      if (modalData.cityTag.length > 0) {
        queryObject["cityTag"] = modalData.cityTag;
      }
      const res = await locationSearchByQuery({
        queryObject,
        searchText: modalData.searchText,
        limit: 15,
      });
      if (res.isSuccess) {
        setModalData((p) => ({
          ...p,
          isSearchLoading: false,
          isDropDown: res.result.length === 0 ? false : true,
          dropDownList: res.result,
          isNoLocationFound: res.result.length === 0,
          matchingCities: res.matchingCities,
        }));
      } else {
        console.error(res.message);
        setModalData((p) => ({
          ...p,
          isSearchLoading: false,
          isNoLocationFound: false,
          dropDownList: [],
        }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCitySelect = (city) => {
    handleContainerUp();
    if (city === "navi mumbai" || city === "mumbai") {
      setModalData((p) => ({
        ...p,
        cityTag: city,
        searchText: "",
        launchingSoon: true,
        isDropDown: false,
        isNotServiceableArea: false,
        popularLocalities: [],
        matchingCities: [],
        dropDownList: [],
      }));
    } else if (
      city === "siliguri" ||
      city === "navi mumbai" ||
      city === "mumbai"
    ) {
      setModalData((p) => ({
        ...p,
        isDropDown: false,
        cityTag: city,
        searchText: "",
        launchingSoon: false,
        isNotServiceableArea: false,
      }));
      handlePopularAreasFetch(city);
    } else {
      setModalData((p) => ({
        ...p,
        // isDropDown: false,
        // searchText: "",
        launchingSoon: false,
        isNotServiceableArea: true,
      }));
    }
  };

  const handleContainerUp = () => {
    setModalData((p) => ({
      ...p,
      isFullModal: true,
    }));
  };

  const handlePopularAreasFetch = async (city) => {
    try {
      setModalData((p) => ({
        ...p,
        popularLocalities: [],
        searchingPopularLocalities: true,
      }));

      const res = await locationSearchByQuery({
        queryObject: {
          city,
        },
        searchText: null,
        limit: 20,
        onlyServiceableArea: true,
      });
      if (res.isSuccess) {
        setModalData((p) => ({
          ...p,
          popularLocalities: res.result,
          searchingPopularLocalities: false,
        }));
      } else {
        setModalData((p) => ({
          ...p,
          popularLocalities: [],
          searchingPopularLocalities: false,
        }));
        console.error(res.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLocationSelect = async (location) => {
    try {
      setIsSelectionProcess(true);
      if (location.serviceAvailable) {
        if (user) {
          // user update
          const res = await update_user_api({
            updateObj: { defaultLocation: location._id },
            queryObj: { _id: user.id },
          });
          // updating the user states in ebo
          setInitialState({
            ...initialState,
            user: { ...res.user, id: res.user._id, token: user.token },
            isLoading: false,
          });
          // updating user in localstorage
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res.user, id: res.user._id, token: user.token })
          );
        } else {
          setTimeout(() => {
            setIsLoginModal(true);
          }, 30000);
        }
        // updating userStates
        setUserStates((p) => ({ ...p, userDefaultLocation: location }));
        localStorage.setItem(
          "USER_STATE",
          JSON.stringify({
            defaultLocation: {
              _id: location._id,
              city: location.city,
              state: location.state,
              pincode: location.pincode,
              location: location.location,
            },
          })
        );
        setSelectedAddress(null);

        handleClose();
      } else {
        setModalData((p) => ({
          ...p,
          isNotServiceableArea: true,
        }));
      }
      setIsSelectionProcess(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <motion.div
      initial={{ translateY: 500, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: 500, opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.3 }}
      className="flex items-end justify-end fixed right-0 bottom-0  left-0 top-0  z-[999] bg-[#0000002a] "
    >
      <div
        onClick={handleClose}
        className="fixed top-0 right-0 left-0 bottom-0 z-[-1] "
      />
      <div
        style={{
          paddingTop: modalData.cityTag ? "9rem" : "6rem",
        }}
        className={`w-full md:w-[30rem] min-h-[35rem] relative h-[35rem]   text-[black]  gap-1 ${
          isSelectionProcess && "blur-[1px]"
        } bg-[white] flex flex-col  rounded-t-2xl md:rounded-b-2xl  overflow-hidden  z-[2] `}
      >
        {isSelectionProcess && (
          <div className=" absolute top-[3rem] right-0 left-0 bottom-0 flex items-center justify-center  z-[4]">
            <div className="loader w-[2rem] "></div>
          </div>
        )}
        <div className="  font-[600] px-4 items-center border-b pt-4 pb-2 mb-2 border-[#f5f5f5] flex w-full justify-between absolute top-0 z-10 bg-white">
          <span className="text-[1rem]">Select Location</span>
          <RxCross2
            onClick={handleClose}
            className="text-[1.25rem] cursor-pointer "
          />
        </div>
        <SearchBar
          modalData={modalData}
          searchInputField={searchInputField}
          handleSearch={handleSearch}
          handleContainerUp={handleContainerUp}
          setModalData={setModalData}
        />
        <div className=" flex flex-col overflow-scroll relative h-[30rem]">
          <div className="flex flex-col px-4 gap-1">
            {/* not serviceable area */}
            {modalData.isNotServiceableArea && (
              <NotServiceable
                modalData={modalData}
                searchInputField={searchInputField}
                setModalData={setModalData}
                handleCitySelect={handleCitySelect}
              />
            )}
            {/* drop down container */}
            <DropDownList
              searchInputField={searchInputField}
              modalData={modalData}
              setModalData={setModalData}
              handleCitySelect={handleCitySelect}
              handleLocationSelect={handleLocationSelect}
            />

            {modalData.launchingSoon && (
              <div className="flex items-center text-[grey] bg-[#F0F5FE] rounded-lg my-2 text-[.825rem] mt-4 gap-4 px-5 py-4">
                <MdOutlineLocationOn className="text-[2.5rem]" />{" "}
                <div className="flex flex-col max-w-[70%]">
                  <span className="font-[600]">
                    Launching Soon in
                    <div className=" inline-block capitalize mx-1">
                      {modalData.cityTag}.
                    </div>
                    Stay tuned
                  </span>
                </div>
              </div>
            )}
          </div>
          {modalData.searchText.length === 0 && (
            <>
              <PopularLocalitiesList
                handleLocationSelect={handleLocationSelect}
                modalData={modalData}
              />
              <ServicealbleAreas
                handleCitySelect={handleCitySelect}
                modalData={modalData}
              />
            </>
          )}
          {modalData.searchText.length === 0 &&
            !modalsRenderInfo.selectLocationModal?.options
              ?.isSavedAddressHidden && (
              <div className="flex flex-col px-4 gap-1">
                {user ? (
                  <SavedAddressesSection handleClose={handleClose} />
                ) : (
                  <div
                    onClick={() => {
                      handleClose();
                      setTimeout(() => {
                        setIsLoginModal(true);
                      }, 500);
                    }}
                    className="w-full flex blue-gradient   mirror-Animation before:w-[20px] justify-center gap-1  items-center text-[1rem] rounded-lg min-h-[3rem] h-[3rem] mt-4  font-[500]  "
                  >
                    <FaLocationPinLock className="text-[1.25rem]" />
                    Login to view Saved addresses
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </motion.div>
  );
}
