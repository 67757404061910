import React, { useContext, useEffect, useState } from "react";
import { CiLocationArrow1 } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import { MdOutlineLocationOn, MdOutlineWorkOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import GLobalStoreContext from "../../../../context/GlobalStoreContext";

export default function SaveAddressAs({ formData, setFormData }) {
  const {sendMessageToNative}=useContext(GLobalStoreContext)
  const [isOther, setIsOther] = useState(false);
  useEffect(() => {
    if (!["venue", "work", "home"].includes(formData.addressLabel)) {
      setIsOther(true);
    }
  }, [formData.addressLabel]);

  const handleVibrate=()=>{
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
  }

  return (
    <>
      <div className=" text-[.825rem] mt-4 mb-1 text-[grey]  ">
        Save address as
      </div>
      {isOther ? (
        <div className="flex text-[.825rem] items-end mt-1 font-[500] ">
          <div
            style={
              isOther
                ? {
                    backgroundColor: "#F0F5FE",
                    borderColor: "#a3c1fe",
                  }
                : {}
            }
            className="border cursor-pointer border-[#eeeeee] px-2 mr-2 flex items-center gap-1 py-1 rounded-md "
          >
            {" "}
            <MdOutlineLocationOn className="relative bottom-[1px] " />
            Other{" "}
          </div>
          <input
            type="text"
            value={formData.addressLabel}
            onChange={(e) =>
              setFormData((p) => ({
                ...p,
                addressLabel: e.target.value,
              }))
            }
            className="px-2 border-b pb-2 w-full text-[.825rem] "
            placeholder="Type here"
          />{" "}
          <RxCross2
            onClick={() => {
              handleVibrate();
              setFormData((p) => ({
                ...p,
                addressLabel: "home",
              }));
              setIsOther(false);
            }}
            className="text-[1.5rem] pb-2 cursor-pointer border-b"
          />
        </div>
      ) : (
        <div className="flex text-[.825rem] mt-1 font-[500] gap-4">
          <div
            onClick={() => {
              handleVibrate();
              setFormData((p) => ({
                ...p,
                addressLabel: "home",
              }));
            }}
            style={
              formData.addressLabel === "home"
                ? {
                    backgroundColor: "#F0F5FE",
                    borderColor: "#a3c1fe",
                  }
                : {}
            }
            className="border  cursor-pointer border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
          >
            {" "}
            <GoHome className="relative bottom-[1px] " />
            Home{" "}
          </div>
          <div
            onClick={() => {
              handleVibrate();
              setFormData((p) => ({
                ...p,
                addressLabel: "venue",
              }));
            }}
            style={
              formData.addressLabel === "venue"
                ? {
                    backgroundColor: "#F0F5FE",
                    borderColor: "#a3c1fe",
                  }
                : {}
            }
            className="border cursor-pointer border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
          >
            {" "}
            <CiLocationArrow1 className="relative bottom-[1px] " />
            Venue{" "}
          </div>{" "}
          <div
            onClick={() => {
              handleVibrate();
              setFormData((p) => ({
                ...p,
                addressLabel: "work",
              }));
            }}
            style={
              formData.addressLabel === "work"
                ? {
                    backgroundColor: "#F0F5FE",
                    borderColor: "#a3c1fe",
                  }
                : {}
            }
            className="border cursor-pointer border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
          >
            {" "}
            <MdOutlineWorkOutline className="relative bottom-[1px] " />
            Work{" "}
          </div>{" "}
          <div
            onClick={() => {
              handleVibrate();
              setFormData((p) => ({
                ...p,
                addressLabel: "",
              }));
            }}
            style={
              isOther
                ? {
                    backgroundColor: "#F0F5FE",
                    borderColor: "#a3c1fe",
                  }
                : {}
            }
            className="border cursor-pointer border-[#eeeeee] px-2 flex items-center gap-1 py-1 rounded-md "
          >
            {" "}
            <MdOutlineLocationOn className="relative bottom-[1px] " />
            Other{" "}
          </div>
        </div>
      )}
    </>
  );
}
