import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
export default function PopularLocalitiesList({
  modalData,
  handleLocationSelect,
}) {
  if (
    modalData.cityTag === "" ||
    modalData.launchingSoon ||
    modalData.isDropDown ||
    modalData.isSearchLoading ||
    modalData.popularLocalities.length === 0
  ) {
    return "";
  }

  return (
    <div className="flex flex-col gap-1 px-4">
      <div className="text-[.875rem]  text-[grey] mt-2 mb-2">
        Popular areas in{" "}
        <div className=" inline-block capitalize">{modalData.cityTag}.</div>
      </div>
      <div className="w-full overflow-x-scroll ">
        <div className="w-auto flex flex-col gap-2">
          <div className="w-auto flex flex-nowrap gap-2">
            {" "}
            {modalData.popularLocalities.slice(0, 10).map((curr, idx) => {
              if (curr.location === "default") {
                return <></>;
              }
              return (
                <div
                  onClick={() => {
                    handleLocationSelect(curr);
                  }}
                  className="flex gap-2 tracking-[.3px] border border-[#f0f5fe] shadow-sm items-center whitespace-nowrap  capitalize rounded-lg cursor-pointer
              px-2 py-[6px]"
                >
                  <BsArrowUpRight className="text-[.75rem] animate-offer-icon text-[grey] relative bottom-[2px]" />
                  <div className="flex  capitalize text-[.875rem] items-center">
                    <span className=" gap-1 flex items-end ">
                      {curr.location},{" "}
                      <span className=" text-[grey] relative top-[1px] ">
                        {curr.pincode}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>{" "}
          <div className="w-auto flex flex-nowrap gap-2">
            {" "}
            {modalData.popularLocalities.slice(9).map((curr, idx) => {
              if (curr.location === "default") {
                return <></>;
              }
              return (
                <div
                  onClick={() => {
                    handleLocationSelect(curr);
                  }}
                  className="flex gap-2 tracking-[.3px] border border-[#f0f5fe] shadow-sm items-center whitespace-nowrap  capitalize rounded-lg cursor-pointer
              px-2 py-[6px]"
                >
                  <BsArrowUpRight className="text-[.75rem] animate-offer-icon text-[grey] relative bottom-[2px]" />
                  <div className="flex  capitalize text-[.875rem] items-center">
                    <span className=" gap-1 flex items-end ">
                      {curr.location},{" "}
                      <span className=" text-[grey] relative top-[1px] ">
                        {curr.pincode}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className=" text-[grey] border  py-2 text-center  font-[400] gap-1 rounded-full text-[.8125rem] mt-3  ">
        {/* <span className="font-[500]  text-[#2136d4]">Note:</span> */}
        Search pincode/area name. If your area is not listed above
      </div>
    </div>
  );
}
