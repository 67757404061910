import React from "react";
// import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";

// const rootElement = document.getElementById("root");
// ReactDOM.render(
// <Router>
//   <App />
// </Router>,
//   rootElement
// );

const container = document.getElementById("root");
const root = createRoot(container); 
root.render(
  <Router>
    <App />
  </Router>
);

reportWebVitals();
