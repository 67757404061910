import React from "react";
import { FaLocationArrow } from "react-icons/fa6";
import LocationNotFound from "./LocationNotFound";

export default function DropDownList({
  modalData,
  handleCitySelect,
  handleLocationSelect,
  searchInputField,
  setModalData,
}) {
  if (modalData.isNotServiceableArea) {
    return <></>;
  }
  return (
    <>
      <LocationNotFound
        modalData={modalData}
        searchInputField={searchInputField}
        setModalData={setModalData}
        handleCitySelect={handleCitySelect}
      />

      {!modalData.isSearchLoading && modalData.isDropDown && (
        <div className="flex flex-col overflow-y-scroll mt-2  h-[30rem] pb-[4rem]  ">
          {modalData.matchingCities.map((curr, idx) => {
            if (curr.city === modalData.cityTag) {
              return "";
            }
            return (
              <div
                onClick={() => {
                  handleCitySelect(curr.city);
                }}
                key={idx + "matching city search result"}
                className="flex gap-1 items-center rounded-lg capitalize    bg-white cursor-pointer
                    px-2 py-2 border-b border-[#f5f5f5] pb-2 mb-2"
              >
                <div className="flex flex-col text-sm justify-center">
                  <span className="capitalize flex items-center gap-1 leading-3 ">
                    <FaLocationArrow className="text-[1rem] text-[black]" />{" "}
                    {curr.city}
                  </span>
                  <span className="text-[.725rem] text-[grey]">city</span>
                </div>
              </div>
            );
          })}
          {modalData.dropDownList.map((curr, idx) => {
            return (
              <div
                key={idx + "search result"}
                onClick={() => {
                  handleLocationSelect(curr);
                }}
                className="flex gap-1 items-center  capitalize rounded-lg bg-white cursor-pointer
                    px-2 py-2 border-b border-[#f5f5f5] pb-2 mb-2"
              >
                {curr.location === "default" ? (
                  <div className="flex flex-col text-sm justify-center">
                    <span className="capitalize flex items-center gap-1 leading-3 ">
                      <FaLocationArrow className="text-[1rem] text-[black]" />{" "}
                      {curr.city}, {curr.pincode}
                    </span>
                    <span className="text-[.725rem] text-[grey]">
                      {curr.state}
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleLocationSelect(curr);
                    }}
                    className="flex flex-col capitalize text-sm justify-center"
                  >
                    <span className="capitalize leading-3 flex items-center gap-1 ">
                      <FaLocationArrow className="text-[1rem] text-[black]" />{" "}
                      {curr.location}, {curr.pincode}
                    </span>
                    <span className="text-[.725rem] text-[grey]">
                      {curr.city}, {curr.state}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
