import moment from "moment";
import React, { memo } from "react";
import DatePicker from "react-mobile-datepicker";
import "./DOBPicker.css";

export default memo(function DOBPicker({
  isOpenDOBSelector,
  setIsOpenDOBSelector,
  setDateOfBirth,
  dateOfBirth,
}) {
  const monthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const dateConfig = {
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: "Mon",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };

  // ---------------- ** dob regex ** --------------------

  const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-([0-9]{4})$/;

  // ---------------- ** functons ** ------------------
  const onSelect = (e) => {
    setDateOfBirth(moment(e).format("DD-MM-YYYY"));
    onCancel();
  };

  const onCancel = () => {
    setIsOpenDOBSelector(false);
  };

  return (
    <DatePicker
      theme={"android"}
      onSelect={onSelect}
      onCancel={onCancel}
      value={
        dateOfBirth !== "" && dobRegex.test(dateOfBirth)
          ? new Date(moment(dateOfBirth, "DD-MM-YYYY").format("YYYY-MM-DD"))
          : new Date()
      }
      headerFormat={"DD-MM-YYYY"}
      isOpen={isOpenDOBSelector}
      dateConfig={dateConfig}
      confirmText={"Done"}
      cancelText={""}
      max={new Date()}
    />
  );
});
