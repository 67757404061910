import React, { useContext } from "react";
import { FaAngleRight, FaLocationArrow } from "react-icons/fa6";
import GLobalStoreContext from "../../../../context/GlobalStoreContext";
import CompRenderingContext from "../../../../context/CompRenderingContext";

export default function LocationSection() {
  const { userStates } = useContext(GLobalStoreContext);
  const { setUniversalModalsRenderInfo } = useContext(CompRenderingContext);
  return (
    <>
      <div className=" text-[.825rem] mt-4 mb-1 text-[grey]  ">
        Your location
      </div>
      <div
        onClick={() => {
          setUniversalModalsRenderInfo((p) => ({
            ...p,
            selectLocationModal: {
              isRender: true,
              options: {
                isSavedAddressHidden: true,
              },
            },
          }));
        }}
        className="flex justify-between items-center border  cursor-pointer border-[#eeeeee] px-2 py-2 rounded-md "
      >
        {userStates?.userDefaultLocation ? (
          <>
            {" "}
            <div className="flex flex-col  ">
              <div className="font-[500] flex items-center gap-1 capitalize text-[.875rem] ">
                <FaLocationArrow className="text-[#2136d4] text-[1rem]" />{" "}
                {userStates.userDefaultLocation.pincode} -{" "}
                {userStates.userDefaultLocation.location}
              </div>{" "}
              <div className="font-[400] capitalize text-[grey] text-[.825rem] leading-4 ">
                {userStates.userDefaultLocation.city},{" "}
                {userStates.userDefaultLocation.state}
              </div>
            </div>
            <div className="text-[.825rem] text-[#2136d4] font-[500]  ">
              Change
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col  ">
              <div className="font-[500] flex items-center gap-1 capitalize text-[.875rem] ">
                <FaLocationArrow className="text-[#2136d4] text-[1rem]" />{" "}
                Select Location
              </div>
            </div>
            <div className="text-[.825rem] text-[#2136d4] font-[500]  ">
              <FaAngleRight className="" />
            </div>
          </>
        )}
      </div>
    </>
  );
}
