import React from "react";
import { MdOutlineLocationOff } from "react-icons/md";
import ServicealbleAreas from "./ServicealbleAreas";
import { FaCircleArrowRight, FaWhatsapp } from "react-icons/fa6";

export default function LocationNotFound({
  modalData,
  searchInputField,
  setModalData,
  handleCitySelect,
}) {
  const content = "Hi, I'm unable to find desired service location.";
  return (
    ((modalData.dropDownList.length === 0 &&
      !modalData.isSearchLoading &&
      modalData.searchText.length >= 6 &&
      !modalData.launchingSoon) ||
      modalData.isNoLocationFound) && (
      <div className="flex flex-col justify-center mt-4 items-center w-full ">
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-1">
            <div className="text-[1.25rem] font-[500] mt-2 text-[grey] ">
              No Result Found
            </div>{" "}
            <div className="text-[.875rem] font-[400] text-[grey] leading-3 ">
              Check spelling mistakes or other location
            </div>
          </div>
          <MdOutlineLocationOff className="text-[4rem] text-[grey]" />
        </div>

        <button
          onClick={() => {
            searchInputField?.current?.focus();
            setModalData((p) => ({ ...p, searchText: "" }));
          }}
          className="text-white blue-gradient mirror-Animation before:w-[50px] rounded-lg py-2 flex w-full  justify-center font-[500] text-[1.125rem] mt-2 bg-[#2136d4] "
        >
          Try another location
        </button>
        <div
          onClick={() => {
            window.location.href = `https://wa.me/9046727027?text=${content}`;
          }}
          className="flex w-full mirror-Animation before:w-[50px]  justify-between items-center rounded-lg border mt-4 p-4 gap-8 border-[#ebebeb]"
        >
          <div className="flex flex-col gap-3">
            <p className="text-[1.125rem] font-[500] leading-5">
              Unable to find your pincode or area name?
            </p>
            <p className="text-[1rem] flex items-center gap-2">
              Chat with us
              <FaCircleArrowRight className="text-[1.25rem]" />
            </p>
          </div>
          <div className="">
            <FaWhatsapp className="text-[4rem] text-[#31a73f] " />
          </div>
        </div>
        <ServicealbleAreas
          handleCitySelect={handleCitySelect}
          modalData={modalData}
        />
      </div>
    )
  );
}
