import { useContext, useEffect } from "react";
import HomePageContext from "../context/HomepageContext";
import AddressContext from "../context/AddressContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import CartContext from "../context/CartContext";

const useFetchEboData = () => {
  const { fetchHomeData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { handleCartPresence } = useContext(CartContext);
  const { fetchAddressData } = useContext(AddressContext);
  const { offerState, getOffers } = useContext(OfferContext);

  useEffect(() => {
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  async function callMethods() {
    await fetchHomeData();
    if (user) {
      await fetchAddressData();
      await handleCartPresence();
    }
    if (offerState.offers.length === 0 || !offerState.offers) {
      await getOffers();
    }
  }
  return { callMethods };
};

export default useFetchEboData;
