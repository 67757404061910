export const serviceableArea = [
  {
    city: "siliguri",
    img: "https://www.incredibleindia.org/content/dam/incredible-india-v2/images/places/siliguri/Coronation-6.jpg",
    label: "Siliguri",
    isLaunching: false,
  },
  // {
  //   city: "navi mumbai",
  //   img: "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/472000/472847-India.jpg",
  //   label: "Navi Mumbai",
  //   isLaunching: true,
  // },
  // {
  //   city: "mumbai",
  //   img: "https://mediaim.expedia.com/destination/2/d8252baff788bfac44356ac9f0478d90.jpg",
  //   label: "Mumbai",
  //   isLaunching: true,
  // },
];
