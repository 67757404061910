import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import ReceiverDetailForm from "./ReceiverDetailForm";
import AuthContext from "../../../../context/AuthContext";
import SaveAddressAs from "./SaveAddressAs";
import LocationSection from "./LocationSection";
import MoreAddressInfo from "./MoreAddressInfo";
import {
  add_address_v2_api,
  update_address_bq,
} from "../../../../api/addressApi";
import GLobalStoreContext from "../../../../context/GlobalStoreContext";
import AddressContext from "../../../../context/AddressContext";

/*
 steps to use this component
 step 1: create state

   const [modalsRenderInfo, setModalsRenderInfo] = useState({
    addAndModiAddress: {
      isRender: false,
      options: {
        customZIndex: 15,
        isEdit: false,
        editingAddressInfo: null
      },
    },
  });

 step 2:: import the component conditionally

    <AnimatePresence>
        {modalsRenderInfo.addAndModiAddress.isRender && (
          <AddAndModiAddress
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>

  step 3: change the state conditionally to render the modal
*/

export default function AddAndModiAddress({
  modalsRenderInfo,
  setModalsRenderInfo,
}) {
  const { user } = useContext(AuthContext);
  const { userStates, setUserStates,sendMessageToNative } = useContext(GLobalStoreContext);
  const { getAddress } = useContext(AddressContext);

  const [formData, setFormData] = useState({
    receiversDetails: {
      name: user.name,
      mobileNumber: parseInt(user.phoneNumber),
      type: "mySelf",
    },
    addressLabel: "home",
    address1: "",
    address2: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    modalsRenderInfo.addAndModiAddress?.options?.isEdit &&
      editInfoDistribution(
        modalsRenderInfo.addAndModiAddress?.options?.editingAddressInfo
      );
    // eslint-disable-next-line
  }, []);

  const handleVibrate=()=>{
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
  }

  const handleClose = () => {
    handleVibrate()
    setModalsRenderInfo((p) => ({
      ...p,
      addAndModiAddress: {
        isRender: false,
        options: {},
      },
    }));
  };

  const msgPop = (msg) => {
    setModalsRenderInfo((p) => ({
      ...p,
      toast1: {
        isRender: true,
        text: msg,
        options: {
          customZIndex: 999,
        },
      },
    }));
  };

  const editInfoDistribution = (address) => {
    if (address.location) {
      setUserStates((p) => ({ ...p, userDefaultLocation: address.location }));
      localStorage.setItem(
        "USER_STATE",
        JSON.stringify({
          defaultLocation: {
            _id: address.location._id,
            city: address.location.city,
            state: address.location.state,
            pincode: address.location.pincode,
            location: address.location.location,
          },
        })
      );
    } else {
      setUserStates((p) => ({ ...p, userDefaultLocation: null }));
      localStorage.setItem(
        "USER_STATE",
        JSON.stringify({
          defaultLocation: null,
        })
      );
    }

    setFormData({
      ...address,
    });
  };

  const handleEdtSubmit = async () => {
    try {
      const savingAddressData = {
        ...formData,
        location: userStates?.userDefaultLocation?._id,
      };
      const res = await update_address_bq({
        queryObj: {
          _id: modalsRenderInfo.addAndModiAddress?.options?.editingAddressInfo
            ._id,
        },
        updateObj: {
          ...savingAddressData,
          location: userStates?.userDefaultLocation?._id,
        },
      });
      if (res.isSuccess) {
        await getAddress();
        handleClose();
        msgPop("Address Edited");
      } else {
        msgPop("Error Occured! Try letter");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (!userStates?.userDefaultLocation?._id) {
        msgPop("Please set the location");
        setIsLoading(false);
        return;
      }
      if (!formData.address1) {
        msgPop("Please fill the address");
        setIsLoading(false);
        return;
      }
      if (
        !formData.receiversDetails.name ||
        !formData.receiversDetails.mobileNumber
      ) {
        msgPop("Please fill the receiver's details");
        setIsLoading(false);
        return;
      }

      if (modalsRenderInfo.addAndModiAddress?.options?.isEdit) {
        await handleEdtSubmit();
        setIsLoading(false);
        return;
      }
      const savingAddressData = {
        ...formData,
        location: userStates?.userDefaultLocation?._id,
      };
      const res = await add_address_v2_api(savingAddressData);
      if (res.isSuccess) {
        await getAddress();
        handleClose();
        msgPop("Address Saved");
      } else {
        msgPop("Error Occured! Try letter");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div
      style={
        modalsRenderInfo.addAndModiAddress?.options?.customZIndex
          ? {
              zIndex: modalsRenderInfo.addAndModiAddress?.options?.customZIndex,
            }
          : {}
      }
      className="fixed top-0 right-0 left-0   z-[5] flex items-end md:items-center justify-center"
    >
      <div onClick={handleClose} className="partial-black-background"></div>
      <motion.div
        initial={{ translateY: 500 }}
        animate={{ translateY: 0 }}
        exit={{ translateY: 700 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="w-full md:w-[30rem] flex flex-col overflow-y-scroll scroll-bar-remove max-h-[90%] bg-white rounded-t-xl md:rounded-b-xl z-[2] border px-4  pb-[58px] bottom-0 fixed top-0"
      >
        <div className=" font-[600] items-center border-b border-[#d9d9d9] pb-1 mb-2 flex w-full justify-between sticky top-0 bg-white py-2 z-10">
          <span className="text-[1rem]">Add New Address</span>
          <RxCross2
            onClick={handleClose}
            className="text-[1.25rem] cursor-pointer "
          />
        </div>
        <ReceiverDetailForm
          user={user}
          formData={formData}
          setFormData={setFormData}
        />{" "}
        <SaveAddressAs formData={formData} setFormData={setFormData} />
        <LocationSection formData={formData} setFormData={setFormData} />
        <MoreAddressInfo formData={formData} setFormData={setFormData} />
        {/* save address button */}
        <button
          onClick={() => {
             handleVibrate();
            !isLoading && handleSubmit();
          }}
          className=" w-full  font-[500] uppercase text-center  rounded-md blue-gradient bg-[#2136d4] mt-4 mb-4 py-3"
        >
          {isLoading ? (
            <div
              style={{ borderTop: "3px solid white" }}
              className=" loader w-[1rem] text-[white]  "
            ></div>
          ) : (
            "save address"
          )}
        </button>
      </motion.div>
    </div>
  );
}
