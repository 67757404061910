import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { get_product_by_query_object } from "../api/productApi";
import { useLocation, useNavigate } from "react-router-dom";
import { get_banners } from "../api/bannersApi";

const ProductPreviewContext = createContext();

export const ProductPreviewProvider = ({ children }) => {
  // ----------------- ** REACT ROUTER HOOKS ** ---------------
  const location = useLocation();
  const navigate = useNavigate();

  // -------------------** CONTEXTS ** ---------------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------------- ** STATES ** --------------------
  const [previewProducts, setPreviewProducts] = useState({
    previewProductData: [],
    isLoading: false,
  });

  const [productListingpageBanners, setProductListingPageBanners] = useState({
    topbanner: "",
    inBetweenProductBanner1: "",
    inBetweenProductBanner2: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [currPage, setCurrPage] = useState({
    page: 0,
    isLast: false,
  }); // for pagination purpose in products preview page
  const [currUserPos, setCurrUserPos] = useState(0);

  // --------------- ** USE EFFECTS ** ------------------

  useEffect(() => {
    const pattern1 = /product/;
    const pattern2 = /checkout/;
    if (
      !(pattern1.test(location.pathname) || pattern2.test(location.pathname))
    ) {
      resetPagePositions();
    }
    handleProductbannersFetch();
    // eslint-disable-next-line
  }, [location]);

  // ------------ ** FUNCTIONS **-----------------

  const handleProductbannersFetch = async () => {
    try {
      if (
        !decodeURIComponent(location.pathname)
          .trim()
          .includes(decodeURIComponent("/products/").trim())
      ) {
        return;
      }
      const res = await get_banners({
        queryObject: {
          placeType: "product_listing_page_banner",
          or: [{ device: "both" }, { device: "sm" }],
        },
        limit: 10,
      });
      if (res.isSuccess) {
        let topPreviewBanner = "";
        let inBetweenPreviewBanner1 = "";
        let inBetweenPreviewBanner2 = "";

        res.result.forEach((curr) => {
          if (parseInt(curr.placementPosition) === 1) {
            // working for top preview banner

            if (curr.showForParticularUrl.length === 0) {
              topPreviewBanner = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  topPreviewBanner = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 2) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner1 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner1 = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 3) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner2 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner2 = curr;
                }
              });
            }
          }
        });

        setProductListingPageBanners({
          topbanner: topPreviewBanner,
          inBetweenProductBanner1: inBetweenPreviewBanner1,
          inBetweenProductBanner2: inBetweenPreviewBanner2,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetPagePositions = async () => {
    setCurrPage({
      page: 0,
      isLast: false,
    });
    setCurrUserPos(0);
  };

  /**
   * return the sortingObj
   * @param {String} sort : the sorting text
   */
  const getSortingObj = useCallback((sort, key) => {
    switch (sort) {
      case "latest":
        return { createdAt: "desc" };

      case "high-to-low":
        return { sellingPrice: "desc" };

      case "low-to-high":
        return { sellingPrice: "asc" };

      case "discount":
        return { webDiscount: "desc" };
      default:
        if (key === "isSpotLightedProduct") return { spotLightIndex: "asc" };
        if (key === "isStartingAt999") return { startingAt999Index: "asc" };
        if (key === "ocassionCategory") return { ocassionCategoryIndex: "asc" };
        return {
          index: "asc",
        };
    }
  }, []);

  const getProducts = useCallback(
    async ({ key, text, sort, projectionString }) => {
      currPage.page === 0 &&
        setPreviewProducts((prev) => ({ ...prev, isLoading: true }));
      const res = await get_product_by_query_object({
        BaseUrl: REACT_APP_BACKEND_URL,
        queryObject: {
          [key]: key === "name" ? { $regex: text, $options: "i" } : text,
          active: true,
          isPreviewInListing:true,
        },
        skip: currPage.page * 54,
        limit: 54,
        sortingObj: getSortingObj(sort, key),
        projectionString,
      });
      setTotalProductsCount(res.productsCount);

      if (res.products.length <= 0 && currPage.page !== 0) {
        setCurrPage({
          ...currPage,
          isLast: true,
        });
        return;
      }
      let newProducts = [];
      if (currPage.page !== 0) {
        newProducts = previewProducts.previewProductData.map((curr) => curr);
      }
      res.products.forEach((element) => {
        newProducts.push(element);
      });

      setPreviewProducts((prev) => ({
        previewProductData: newProducts,
        isLoading: false,
      }));
    },
    [REACT_APP_BACKEND_URL, currPage, getSortingObj, previewProducts]
  );

  // ------------------> fetch products for and return the fetched products
  const fetchProductsByQuery = async (
    queryObject,
    skip,
    limit,
    sortingObj,
    projectionString,
    pageLocation
  ) => {
    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: { ...queryObject, active: true },
      skip,
      limit,
      sortingObj,
      projectionString,
    });

    if (res.products.length === 0 && pageLocation === "_SINGLE_PRODUCT_PAGE")
      navigate("/");

    return res.products;
  };

  // get product array using search text
  const getBySearchText = useCallback(
    async (searchText) => {
      try {
        const res = await get_product_by_query_object({
          BaseUrl: REACT_APP_BACKEND_URL,
          queryObject: {
            $or: [
              { name: { $regex: searchText, $options: "i" } }, // Case-insensitive search on productName
              { _id: searchText.toUpperCase() }, // Match against _id
            ],
            active: true,isPreviewInListing:true,
          },
          skip: 0,
          limit: 8,
          sortingObj: {},
          projectionString:
            "_id name images sellingPrice costPrice webDiscount ",
        });

        return res.products;
      } catch (error) {
        console.log(error);
      }
    },
    [REACT_APP_BACKEND_URL]
  );

  const filterService = async () => {};

  return (
    <ProductPreviewContext.Provider
      value={{
        getBySearchText,
        previewProducts,
        showFilter,
        setShowFilter,
        setPreviewProducts,
        currPage,
        setCurrPage,
        resetPagePositions,
        filterService,
        getProducts,
        currUserPos,
        setCurrUserPos,
        totalProductsCount,
        setTotalProductsCount,
        fetchProductsByQuery,
        productListingpageBanners,
      }}
    >
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewContext;
