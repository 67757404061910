import React, { createContext, useState } from "react";

const CompRenderingContext = createContext();

export const CompRenderingProvider = ({ children }) => {
  // ------------------- ** LOGIN MODAL ** -------------------

  const [universalModalsRenderInfo, setUniversalModalsRenderInfo] = useState({
    selectLocationModal: {
      isRender: false,
      options: {},
    },
  });

  const [isLoginModal, setIsLoginModal] = useState(false); // side bar login modal
  const [loginModal, setLoginModal] = useState(false); // in between login modal

  const [calendarValue, setCalendarValue] = useState(null);

  const [isAddressSelector, setIsAddressSelector] = useState(false);
  const [header, setHeader] = useState({
    title: "ebo",
    isRender: true,
  });
  const [isCancel, setIsCancel] = useState(false);
  const [cancelData, setCancelData] = useState({
    isCancel: false,
    orderId: "",
    reason: "",
  });
  const [isPopup, setIsPopup] = useState(false);
  const [isRating, setIsRating] = useState({
    orderId: "",
    isRender: false,
    afterSuccessFun: () => {},
  });
  const [warningModal, setWarningModal] = useState({
    text: "Do you realy want to remove ?",
    isRender: false,
    funToBeExecuted: null,
    funToBeExecutedOnNo: null,
    textInsteadOfYes: "",
    textInsteadOfNo: "",
  });

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    isRender: false,
  });
  const [successMsg, setSuccessMsg] = useState({
    msg: "OTP Sent",
    isRender: false,
  });

  //for coupon applied pop-up component

  const [couponModal, setCouponModal] = useState({
    img: "/img/couponSuccessLogo.svg",
    couponName: "",
    savAmount: "",
    isRender: false,
  });

  const [showBill, setShowBill] = useState({
    isRender: false,
    discountPrice: 0,
  });
  const [navAddressPop, setNavAddressPop] = useState(false);

  // ------------ opening and closing of addon detail cards --------------------
  const [isAddonDetailCard, setIsAddonDetailCard] = useState({
    addon: null,
    isRender: false,
  });

  //--------- referral code applied successful modal-------
  const [referralAppliedModal, setReferralAppliedModal] = useState(false);

  // state for popup model diplayed when i button is clicked on wallet page
  const [boxInfo, setBoxInfo] = useState({
    isRender: false,
    name: "",
    isClicked: null,
  });

  // pop up modal for diplay input box for instrcution on cart page
  const [instructionInputBox, setInstructionInputBox] = useState({
    isRender: false,
    order: null,
  });

  const [confrimationDoneUiRender, setConfirmationDoneUiRender] = useState({
    isRender: false,
    type: "",
  });

  return (
    <CompRenderingContext.Provider
      value={{
        universalModalsRenderInfo,
        setUniversalModalsRenderInfo,
        isLoginModal,
        setIsLoginModal,
        isAddressSelector,
        setIsAddressSelector,
        calendarValue,
        setCalendarValue,
        isCancel,
        setIsCancel,
        cancelData,
        setCancelData,
        isPopup,
        setIsPopup,
        isRating,
        setIsRating,
        warningModal,
        setWarningModal,
        header,
        setHeader,
        showBill,
        setShowBill,
        navAddressPop,
        setNavAddressPop,
        couponModal,
        setCouponModal,
        toastMsg,
        setToastMsg,
        successMsg,
        setSuccessMsg,
        loginModal,
        setLoginModal,
        isAddonDetailCard,
        setIsAddonDetailCard,
        referralAppliedModal,
        setReferralAppliedModal,
        boxInfo,
        setBoxInfo,
        instructionInputBox,
        setInstructionInputBox,
        confrimationDoneUiRender,
        setConfirmationDoneUiRender,
      }}
    >
      {children}
    </CompRenderingContext.Provider>
  );
};

export default CompRenderingContext;
